const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },{
    path: ["/privacy-policy"],
    exact: true,
    component: "PrivacyPolicy",
  },{
    path: ["/data-safety"],
    exact: true,
    component: "DataPolicy",
  },
];

export default routes;
